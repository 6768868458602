<template>
  <div>
    <RightSideBar
      v-if="isOpen"
      :loading="isLoading"
      @close="isOpen=false"
      button-class="bg-dynamicBackBtn text-white"
      :close-button="true"
    >
      <template v-slot:title>
          <div class="flex justify-start items-center">
            <back-button @onClick="isOpen = false" variant="secondary" />
            <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
              View Details
            </span>
          </div>
        </template>
      <template>
        <div class="flex flex-col gap-0 w-full">
          <EmployeeDetails :employee-info="delegator" title="DELEGATOR" />
          <EmployeeDetails :employee-info="delegatee" title="DELEGATEE" />
        </div>
      </template>
      <template>
        <div class="flex flex-col justify-start items-start gap-2">
          <template>
            <div class="w-full flex justify-between items-center gap-2">
              <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
                <Icon icon-name="calendar" class="text-romanSilver mt-1" size="xs" />
                <div class="flex flex-col justify-start items-start">
                  <h6 class="font-normal text-xs text-mediumSeaGreen">Reason for Delegation</h6>
                  <p class="font-semibold text-jet text-xs uppercase">
                    <span v-if="delegationInfo.delegationReason === 'vacant'">Vacant Position</span>
                    <span v-if="delegationInfo.delegationReason === 'unavailable'">Unavailable Employee</span>
                    <span v-if="delegationInfo.delegationReason === 'support'">Support</span>
                  </p>
                </div>
              </Card>
              <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
                <Icon icon-name="calendar" class="text-romanSilver mt-1" size="xs" />
                <div class="flex flex-col justify-start items-start">
                  <h6 class="font-normal text-xs text-flame">Support Type</h6>
                  <p class="font-semibold text-jet text-xs uppercase">
                    <span v-if="delegationInfo.supportType === 'time'">Time Management</span>
                    <span v-if="delegationInfo.supportType === 'skill'">Skill Management</span>
                    <span v-if="delegationInfo.supportType === 'engagement'" style="font-size:10px">Engagement & Collaboration</span>
                    <span v-if="delegationInfo.supportType === 'resource'">Resource Efficiency</span>
                    <span v-if="delegationInfo.supportType === 'growth'">Growth & Development</span>
                  </p>
                </div>
              </Card>
            </div>
          </template>
          <template>
            <div class="w-full flex justify-between items-center gap-2">
              <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
                <Icon icon-name="icon-grid" class="text-romanSilver mt-1" size="xs" />
                <div class="w-full flex flex-col justify-start items-start">
                  <h6 class="font-normal text-xs text-flame">Delegated Tasks</h6>
                  <div class="flex justify-between w-full">
                    <div class="flex flex-row justify-start items-center gap-1">
                      <p class="font-semibold text-jet text-xs uppercase">specified</p>
                      <small class="text-blueCrayola" style="font-size:10px">
                        {{ delegationInfo.delegatedTasks.length }}
                      </small>
                    </div>
                    <div class="cursor-pointer" @click="isOpenDelegatedTasks = true">
                      <small class="text-blueCrayola cursor-pointer" style="font-size:10px">
                        View Trail
                      </small>
                    </div>
                  </div>
                </div>
              </Card>
              <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
                <Icon icon-name="calendar" class="text-romanSilver mt-1" size="xs" />
                <div class="flex flex-col justify-start items-start">
                  <h6 class="font-normal text-xs text-blueCrayola">Delegation Period</h6>
                  <p class="flex flex-row justify-start font-semibold text-jet text-xs uppercase">
                    {{ $DATEFORMAT(new Date(delegationInfo.delegationStart), "MMM dd, yyyy") }} -
                    {{ $DATEFORMAT(new Date(delegationInfo.delegationEnd), "MMM dd, yyyy") }}
                  </p>
                </div>
              </Card>
            </div>
          </template>
          <template>
            <div class="w-full flex justify-between items-center gap-2">
              <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
                <Icon icon-name="icon-grid" class="text-romanSilver mt-1" size="xs" />
                <div class="flex flex-col justify-start items-start">
                  <h6 class="font-normal text-xs text-carrotOrange">Initiated By</h6>
                  <p class="font-semibold text-jet text-xs uppercase">
                    <span v-if="delegationInfo.initiatedBy">
                      {{ delegationInfo.initiatedBy.fname }} {{ delegationInfo.initiatedBy.lname }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </div>
              </Card>
              <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
                <Icon icon-name="icon-grid" class="text-romanSilver mt-1" size="xs" />
                <div class="w-full flex flex-col justify-start items-start">
                  <h6 class="font-normal text-xs text-flame">Approval Status</h6>
                  <div class="flex justify-between w-full">
                    <p class="font-semibold text-jet text-xs uppercase">
                      <span v-if="delegationInfo.approvalStatus">{{ delegationInfo.approvalStatus }}</span>
                      <span v-else>-</span>
                    </p>
                    <div @click="priviledgedActions(delegationInfo.delegationId)">
                    <small class="text-blueCrayola cursor-pointer" style="font-size:10px">
                      View Trail
                    </small>
                  </div>
                  </div>
                </div>
              </Card>
            </div>
          </template>
        </div>
      </template>
    </RightSideBar>
    <RightSideBar
      v-if="isOpenDelegatedTasks"
      @close="isOpenDelegatedTasks = false"
      button-class="bg-dynamicBackBtn text-white"
      :close-button="true"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <back-button @onClick="isOpenDelegatedTasks = false" variant="secondary" />
          <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            Delegated Tasks
          </span>
        </div>
      </template>
      <template v-slot:default>
        <div class="grid grid-cols-2 gap-4">
          <Card v-for="task in delegationInfo.delegatedTasks" :key="task.id">
            <div class="flex flex-row items-center justify-start gap-2 mx-5 my-3">
              <Icon icon-name="icon-grid" class="text-romanSilver" size="xxs" />
              <h6 class="font-semibold text-xs text-jet capitalize">{{ task.specify }} </h6>
            </div>
          </Card>
        </div>
      </template>
    </RightSideBar>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import Card from "@scelloo/cloudenly-ui/src/components/card"
import RightSideBar from "@/components/RightSideBar"

export default {
  components: {
    Card,
    BackButton,
    RightSideBar,
    EmployeeDetails: () => import('./details'),
  },
  data(){
    return {
      isLoading: true,
      isOpen: false,
      delegator: {},
      delegatee: {},
      delegation: {},
      delegationInfo: {},
      isOpenDelegatedTasks: false,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'admin'
    }
  },
  methods: {
    async priviledgedActions(requestId){
      try {
        if (this.mode === 'admin') {
          await this.$handlePrivilege('delegationManagementHR', 'viewApprovalStatus')
        }
        this.$emit('trail', requestId)
      } catch (error) {
        const message = 'You do not have permission to perform this task'
        this.$toasted.error(message, { duration: 3000 })
      }
    },
    toggle(payload){
      this.isOpen = !this.isOpen

      if(payload.delegationdetail.delegationReason === 'vacant'){
        this.delegation = payload.delegationdetail
        this.delegator = this.getDesignationInformation(payload.delegator)
        this.delegatee = this.getEmployeeInformation(payload.delegatee)
      } else {
        this.delegation = payload.delegationdetail
        this.delegator = this.getEmployeeInformation(payload.delegator)
        this.delegatee = this.getEmployeeInformation(payload.delegatee)
      }
      this.delegationInfo = {
        delegationId: payload.id,
        initiatedBy: payload.initiatedBy,
        delegationReason: payload.delegationReason,
        supportType: payload.delegationdetail.supportType,
        delegatedTasks: payload.delegationdetail.delegatedTask,
        delegationStart: payload.delegationStart,
        delegationEnd: payload.delegationEnd,
        approvalStatus: payload.status
      }
      this.isLoading = false
    },
    getDesignationInformation: (payload) => ({
      ...payload,
      fname: payload.name,
      designation: payload.name,
      orgFunction: payload.orgFunction?.name,
      reportingTo: payload.reportsTo?.name,
      level: payload.level?.name,
    }),
    getEmployeeInformation: (payload) => ({
      ...payload,
      orgFunction: payload.orgFunction?.name,
      designation: payload.userDesignation?.name,
      level: payload.userLevel?.name,
      reportingTo: payload.userReportingTo?.name,
      officeAddress: payload.employeeOffice?.name,
    }),
  }
};
</script>
